import CollapsableReview from '../classes/CollapsableReview';

document.addEventListener('DOMContentLoaded', () => {
    document.querySelectorAll('.review').forEach((review) => {
        const { height } = review.getBoundingClientRect();

        if (height <= 334) return;
        new CollapsableReview(review);
    });
});
