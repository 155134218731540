class Alert {
    /**
     * @param {String} text
     */
    constructor(text) {
        this.text = text;
        this.alertElement = this._createAlertElement();

        this._addCloseEventListeners();

        this._show();

        this.timerId = setTimeout(() => {
            this.close();
        }, 5000);

        if (window.appAlert) window.appAlert.close();
        window.appAlert = this;
    }

    _createAlertElement() {
        const div = document.createElement('div');
        const template = `
            <div class="alert">
                <div class="alert__overlay"></div>
                <div class="alert__body">
                    <div class="alert__icon"></div>
                    <div class="alert__text">${this.text}</div>
                    <div class="alert__button button button_success">Хорошо</div>
                </div>
            </div>
        `;
        div.innerHTML = template.trim();

        return div.firstChild;
    }

    _addCloseEventListeners() {
        this.alertElement
            .querySelector('.alert__overlay')
            .addEventListener('click', () => {
                this.close();
            });

        this.alertElement
            .querySelector('.alert__button')
            .addEventListener('click', () => {
                this.close();
            });
    }

    _show() {
        document.body.appendChild(this.alertElement);
    }

    close() {
        clearTimeout(this.timerId);
        this.alertElement.remove();
    }
}

export default Alert;
