let metaSessId = null;

const getSessId = () => {
    let sessId = window?.BX?.bitrix_sessid?.();

    if (!sessId) {
        if (!metaSessId) {
            metaSessId = document.querySelector('input[name="sessid"]').value;
        }
        sessId = metaSessId;
    }

    return sessId;
};

export default getSessId;
