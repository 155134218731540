import validate from 'validate.js';
import { reloadGoogleCaptcha } from '@/utils/googleCaptcha';
import './form.scss';

const form = ({
    selectorForm,
    schemaValidation,
    apiPromise,
    onSuccessForm,
    onErrorForm,
    blurElementValidation,
}) => {
    const sendForm = (e) => {
        e.preventDefault();
        e.stopPropagation();

        const values = getValuesForm(selectorForm);
        const errors = validate(values, schemaValidation);
        const eventsListener = ['rating', 'captcha'];

        showErrorsForm(selectorForm, errors);

        if (!errors) {
            if (values['g-recaptcha-response']) {
                values['captcha'] = values['g-recaptcha-response'];
                delete values['g-recaptcha-response'];
            }
            if (values['agreeWithTheProcessingOfPersonalData']) {
                delete values['agreeWithTheProcessingOfPersonalData'];
            }
            apiPromise(values).then(({ data }) => {
                if (
                    data.status === 'error' ||
                    data.status === '400' ||
                    data?.success === false
                ) {
                    onErrorForm && onErrorForm(selectorForm, data);
                } else {
                    onSuccessForm && onSuccessForm(selectorForm);
                }
            });
        }

        if (blurElementValidation) {
            const formInputs = selectorForm.querySelectorAll(
                'input, textarea, select'
            );

            eventsListener.forEach((name) => {
                document.addEventListener(name, (e) => {
                    const target = e.detail.block;
                    showErrorElement(target, target.name);
                });
            });

            formInputs.forEach((element) => {
                element.addEventListener('change', (e) =>
                    showErrorElement(e.target, e.target.name)
                );
            });
        }
    };

    const showErrorElement = (target, name) => {
        const value = getValuesForm(selectorForm);
        const errs = validate(value, schemaValidation);
        showErrorsForm(target, errs[name], true);
    };

    selectorForm.addEventListener('submit', sendForm);
};

const getValuesForm = (form) => {
    const values = {};
    for (const [key, value] of new FormData(form).entries()) {
        values[key] = value;
    }
    return values;
};

const createErrorLine = () => {
    let div = document.createElement('div');
    div.className = 'error-messages';
    return div;
};

const resetForm = (form) => {
    const inputs = form.querySelectorAll('.input-field');
    const captcha = form.querySelectorAll('.g-recaptcha');

    form.reset();

    inputs.forEach((input) => {
        const event = new Event('change');
        const inputOuter = input.closest('.input-outer');
        inputOuter.classList.remove('form-field--success');
        inputOuter.classList.remove('form-field--error');
        input.dispatchEvent(event);
    });

    captcha.forEach((item) => {
        reloadGoogleCaptcha(item);
    });
};

const showErrorsForm = (form, errors, single = false) => {
    if (!single) {
        [...form.elements].forEach((line) =>
            showErrorElement(line, errors, single)
        );
    } else {
        showErrorElement(form, errors, single);
    }
};

const showErrorElement = (line, errors, single = false) => {
    const group = line.closest('.form-field');
    if (group) {
        let error = group.querySelector('.error-messages');

        if (!error) {
            error = createErrorLine();
            group.append(error);
        }

        if ((!single && errors && errors[line.name]) || (single && errors)) {
            group.classList.add('form-field--error');
            group.classList.remove('form-field--success');
            error.innerHTML = !single ? errors[line.name] : errors;
        } else {
            group.classList.remove('form-field--error');
            group.classList.add('form-field--success');
            error.innerHTML = '';
        }
    }
};

export default form;
export { resetForm, showErrorsForm };
