class Popup {
    /**
     * @param {Element} popup
     * @param {NodeList} links
     * @param {Number} timeout
     */
    constructor(popup, links, timeout = 300) {
        this.popup = popup;
        this.links = [...links];
        this.timeout = timeout;

        this.pending = false;

        this.links.forEach((link) => {
            link.addEventListener('click', () => this.open());
        });

        this.popup
            .querySelector('.popup__close')
            .addEventListener('click', () => this.close());

        this.popup
            .querySelector('.popup__overlay')
            .addEventListener('click', () => this.close());
    }

    open() {
        if (this.pending) return;

        this.popup.classList.add('show');
        document.body.classList.add('overflow_hidden');

        window.requestAnimationFrame(() => {
            this.popup.classList.add('active');
        });
    }

    close() {
        if (this.pending) return;

        this.popup.classList.remove('active');
        document.body.classList.remove('overflow_hidden');

        this.pending = true;
        setTimeout(() => {
            this.popup.classList.remove('show');
            this.pending = false;
        }, this.timeout);
    }
}

export default Popup;
