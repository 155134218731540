function onloadCallbackCaptcha() {
    window.gooleCaptcha.forEach((captcha) => {
        if (window.grecaptcha) {
            const googleSiteKey =
                window.appParams.services.recaptcha.key !== undefined
                    ? window.appParams.services.recaptcha.key
                    : null;

            if (!captcha.dataset.grecaptchaId && googleSiteKey !== null) {
                const widgetId = window.grecaptcha.render(
                    captcha.getAttribute('id'),
                    {
                        sitekey: googleSiteKey,
                        'expired-callback': () => {
                            reloadGoogleCaptcha(captcha);
                        },
                        'error-callback': () => {
                            reloadGoogleCaptcha(captcha);
                        },
                        callback: (response) => {
                            verifyCallback(response, captcha);
                        },
                    }
                );

                captcha.dataset.grecaptchaId = widgetId;
                captcha.querySelector('.g-recaptcha-response').dataset.require =
                    'recaptcha';
            }
        }
    });
}

const getGoogleCaptcha = () => {
    window.gooleCaptcha = document.querySelectorAll('.g-recaptcha');
    if (window.gooleCaptcha.length) {
        if (!window.grecaptcha) {
            const script = document.createElement('script');
            script.src = `//www.google.com/recaptcha/api.js?onload=onloadCallbackCaptcha&render=explicit`;
            script.async = true;
            script.defer = true;
            document.body.appendChild(script);
        } else {
            onloadCallbackCaptcha();
        }
    }
};

const verifyCallback = (response, captcha) => {
    captcha.querySelector('.g-recaptcha-response').value = response;

    document.dispatchEvent(
        new CustomEvent('captcha', {
            detail: { block: captcha.querySelector('.g-recaptcha-response') },
        })
    );
};

const reloadGoogleCaptcha = (captcha) => {
    if (captcha) {
        const widgetId = captcha.dataset.grecaptchaId;
        window.grecaptcha.reset(widgetId);
    }
};

const errorRecaptcha = (captcha, text) => {
    if (captcha.parentElement) {
        let parent = captcha.parentElement.querySelector('.review-form');
        let errorText = 'Вы не прошли проверку "Я не робот"';
        let reloadCaptcha = true;
        if (text) {
            errorText = text;
        }

        if (!parent && captcha.closest('.popup__body')) {
            parent = captcha
                .closest('.popup__body')
                .querySelector('.review-form');
            reloadCaptcha = false;
        }

        if (parent) {
            parent.classList.add('show');
            parent.innerHTML = errorText;
        }

        if (reloadCaptcha) {
            reloadGoogleCaptcha(captcha);
        }
    }
};

window.onloadCallbackCaptcha = onloadCallbackCaptcha;

document.addEventListener('DOMContentLoaded', () => {
    getGoogleCaptcha();
});

export { reloadGoogleCaptcha, errorRecaptcha };
