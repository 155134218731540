import axios from 'axios';
import getSessId from './getSessId';

const ClientApi = axios.create({
    baseURL: '/clientapi/',
    transformRequest: [
        function (data) {
            if (data) {
                return JSON.stringify(data);
            }
        },
    ],
    transformResponse: [
        function (response) {
            return JSON.parse(response);
        },
    ],
    headers: {
        'Content-Type': 'text/plain',
        'X-Requested-With': 'XMLHttpRequest',
        sessid: getSessId(),
    },
});

export { ClientApi };
export default axios;
