class RatingStarsInput {
    /**
     * @param {Element} ratingStarsInput
     */
    constructor(ratingStarsInput) {
        this.input = ratingStarsInput.querySelector(
            '.rating-stars-input__input'
        );
        this.stars = [
            ...ratingStarsInput.querySelectorAll('.rating-stars-input__star'),
        ];

        this._onMouseOver = this._onMouseOver.bind(this);
        this._onMouseLeave = this._onMouseLeave.bind(this);
        this._onClick = this._onClick.bind(this);

        this.entered = false;
        this._value = 0;

        this.stars.forEach((star) => {
            star.addEventListener('mouseover', this._onMouseOver);
            star.addEventListener('click', this._onClick);
        });

        ratingStarsInput
            .querySelector('.rating-stars-input__stars')
            .addEventListener('mouseleave', this._onMouseLeave);
    }

    _onMouseOver({ target }) {
        if (this.entered) return;

        const index = this.stars.findIndex((star) => star === target);
        this._highlightStars(index);
    }

    _onMouseLeave() {
        if (this.entered) return;

        this._clearStars();
    }

    _onClick({ target }) {
        const index = this.stars.findIndex((star) => star === target);
        this.value = index + 1;
    }

    _highlightStars(index) {
        this.stars.forEach((star, i) => {
            i <= index
                ? star.classList.add('rating-stars-input__star_filled')
                : star.classList.remove('rating-stars-input__star_filled');
        });
    }

    _clearStars() {
        this.stars.forEach((star) =>
            star.classList.remove('rating-stars-input__star_filled')
        );
    }

    get value() {
        return this._value;
    }

    set value(val) {
        this.input.value = val;
        this._value = val;

        this.entered = !!val;
        this.entered
            ? this._highlightStars(this._value - 1)
            : this._clearStars();

        document.dispatchEvent(
            new CustomEvent('rating', {
                detail: {
                    block: this.input,
                },
            })
        );
    }
}

export default RatingStarsInput;
