import form, { resetForm } from '@/blocks/form';
import Reviews from '@/api/Reviews';
import Alert from '@/classes/Alert';

const formInit = () => {
    const forms = document.querySelectorAll('form.review-form');

    const schemaValidationService = {
        email: {
            presence: {
                message: '^Неверный формат данных',
            },
            email: {
                message: '^Неверный формат данных',
            },
        },
        name: {
            presence: {
                message: '^Обязательное поле',
            },
            length: {
                minimum: 1,
                message: '^Обязательное поле',
            },
        },
        city: {
            presence: {
                message: '^Обязательное поле',
            },
            length: {
                minimum: 1,
                message: '^Обязательное поле',
            },
        },
        rating: {
            presence: {
                message: '^Обязательное поле',
            },
            length: {
                minimum: 1,
                message: '^Обязательное поле',
            },
        },
        text: {
            presence: {
                message: '^Обязательное поле',
            },
            length: {
                minimum: 1,
                message: '^Обязательное поле',
            },
        },
        'g-recaptcha-response': {
            presence: {
                message: '^Вы не прошли проверку "Я не робот"',
            },
            length: {
                minimum: 1,
                message: '^Вы не прошли проверку "Я не робот"',
            },
        },
        agreeWithTheProcessingOfPersonalData: {
            presence: {
                message: '^Поле обязательно к заполнению',
            },
            inclusion: {
                within: ['on'],
                message: '^Поле обязательно к заполнению',
            },
        },
    };

    const schemaValidationEmployee = {
        email: {
            presence: {
                message: '^Неверный формат данных',
            },
            email: {
                message: '^Неверный формат данных',
            },
        },
        name: {
            presence: {
                message: '^Обязательное поле',
            },
            length: {
                minimum: 1,
                message: '^Обязательное поле',
            },
        },
        city: {
            presence: {
                message: '^Обязательное поле',
            },
            length: {
                minimum: 1,
                message: '^Обязательное поле',
            },
        },
        status: {
            presence: {
                message: '^Обязательное поле',
            },
            inclusion: {
                within: ['old', 'current'],
                message: '^Обязательное поле',
            },
        },
        isRecommend: {
            presence: {
                message: '^Обязательное поле',
            },
        },
        text: {
            presence: {
                message: '^Обязательное поле',
            },
            length: {
                minimum: 1,
                message: '^Обязательное поле',
            },
        },
        'g-recaptcha-response': {
            presence: {
                message: '^Вы не прошли проверку "Я не робот"',
            },
            length: {
                minimum: 1,
                message: '^Вы не прошли проверку "Я не робот"',
            },
        },
        agreeWithTheProcessingOfPersonalData: {
            presence: {
                message: '^Поле обязательно к заполнению',
            },
            inclusion: {
                within: ['on'],
                message: '^Поле обязательно к заполнению',
            },
        },
    };

    forms.forEach((selectorForm) => {
        if (selectorForm?.dataset?.type) {
            form({
                selectorForm,
                schemaValidation:
                    selectorForm.dataset.type === 'employee'
                        ? schemaValidationEmployee
                        : schemaValidationService,
                apiPromise:
                    selectorForm.dataset.type === 'employee'
                        ? Reviews.employee
                        : Reviews.service,
                onSuccessForm: (form) => {
                    document
                        .querySelectorAll('.popup.show.active')
                        .forEach((popup) => {
                            const buttonClose = popup.querySelector(
                                '.popup__close'
                            );
                            buttonClose && buttonClose.click();
                        });
                    new Alert('Ваш отзыв успешно отправлен на модерацию');
                    resetForm(form);
                },
                onErrorForm: (form, data) => {
                    resetForm(form);
                    if (data?.message) alert(data.message);
                },
                blurElementValidation: true,
            });
        }
    });
};

document.addEventListener('DOMContentLoaded', formInit);
