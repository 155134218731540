import Popup from '@/classes/Popup';

document.addEventListener('DOMContentLoaded', () => {
    new Popup(
        document.querySelector('#service-popup'),
        document.querySelectorAll('.popup-link[data-popup="service-popup"]')
    );
    new Popup(
        document.querySelector('#employee-popup'),
        document.querySelectorAll('.popup-link[data-popup="employee-popup"]')
    );
});
