import createElementFromString from '../utils/createElementFromString';

class CollapsableReview {
    /**
     * @param {Element} review
     */
    constructor(review) {
        this.review = review;

        this.initialize();
    }

    initialize() {
        const overlay = createElementFromString(`
            <div class="review__overlay">
                <div class="review__expand">Развернуть отзыв</div>
            </div>
        `);

        const collapseButton = createElementFromString(`
            <div class="review__collapse">Свернуть отзыв</div>
        `);

        this.review.appendChild(overlay);
        overlay.addEventListener('click', () => {
            this.expand();
        });

        this.review.appendChild(collapseButton);
        collapseButton.addEventListener('click', () => {
            this.collapse();
        });

        this.collapse();
    }

    collapse() {
        this.review.classList.add('review_collapsed');
    }

    expand() {
        this.review.classList.remove('review_collapsed');
    }
}

export default CollapsableReview;
